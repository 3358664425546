.edit-icon[data-v-4aaf3a60] {
  float: right;
  color: #2ebef7;
}
.edit-icon[data-v-4aaf3a60]:hover {
    cursor: pointer;
}
.delete-icon[data-v-4aaf3a60] {
  float: right;
  color: red;
}
.delete-icon[data-v-4aaf3a60]:hover {
    cursor: pointer;
}
