.html-build[data-v-c9746e24] {
  min-height: 60vh;
}
.html-build .html-build-left .avaliable-tag[data-v-c9746e24] {
    float: left;
    margin-right: 10px;
    height: 1em;
    line-height: 1em;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #007acc;
    color: #007acc;
}
.html-build .html-build-left .avaliable-tag[data-v-c9746e24]:hover {
      cursor: move;
}
.html-build .html-build-left .tags-container[data-v-c9746e24] {
    min-height: 60vh;
    padding-top: 10px;
    border-right: 1px solid #eee;
}
.html-build .html-build-left .chosen-tag[data-v-c9746e24] {
    background-color: #f58a8a;
    border-color: #fd2a2a;
}
.html-build .html-build-right[data-v-c9746e24] {
    height: 60vh;
    overflow: scroll;
}
.html-build .html-build-right .node-root[data-v-c9746e24] {
      border: 1px dashed #67aad6;
      padding: 10px;
}
.html-build .html-build-right .node-root[data-v-c9746e24] .node-item {
        padding: 10px;
        border: 1px dashed #67aad6;
}
.html-build .html-build-right .node-root[data-v-c9746e24] .node-draggable {
        padding: 10px;
}
.html-build .html-build-right .node-root[data-v-c9746e24] .node-label {
        margin-left: 15px;
}
.datasource-row[data-v-c9746e24] {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #f1f1f1;
}
.datasource-row[data-v-c9746e24]:hover {
    background-color: #f1f1f1;
}
.datasource-row button[data-v-c9746e24] {
    float: right;
    margin-top: 5px;
    margin-right: 15px;
    outline: none;
    border-radius: 5px;
    border: none;
    padding: 10px;
    font-size: var(--rootFontSize4);
    color: #fff;
    cursor: pointer;
}
.datasource-row .edit-btn[data-v-c9746e24] {
    background-color: #67aad6;
}
.datasource-row .delete-btn[data-v-c9746e24] {
    background-color: #fc5555;
}
.datasource-row .delete-btn[data-v-c9746e24]:disabled {
      background-color: #c8c8c8;
      cursor: not-allowed;
}
